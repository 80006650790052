import MembershipTiles from './membershipTilesSwiper';
import { debounce } from 'lodash';

let windowWidth = $(window).width();

$(window).on('load resize', debounce((e) => {
    // Check window width has actually changed and it's not just iOS triggering a resize event on scroll
    if (e.type === 'load' || $(window).width() !== windowWidth) {
        // Update the window width for next time
        windowWidth = $(window).width();
        if ($(window).width() > 900) {
            MembershipTiles.destroy();
        } else {
            MembershipTiles.init();
        }
    }
}, 300));
